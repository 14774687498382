.area {
  width: 75vh;
  height: 50vh;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;

  border-radius: 25px;
  box-sizing: border-box;
  resize: none;
}

.limit{
  font-size: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
  width: 100%;
  
  /* padding: '0.5rem'; */
}

.radio{
  margin: 10px;
  font-size: 15px;
  padding: 0.5rem;
  width: 80vw;
  border: 1px solid #e2e8f0;
  resize: none;
  overflow-x: hidden;
}

.radio-after{
  font-size: 15px;
  margin: 5px;
  /* width: 10vw; */
  outline: none;
  padding: 0.2rem;
}

.q-container{
  /* background-color: aqua; */
  border: blueviolet 1px solid;
  border-radius: 25px;

  padding: 0.5rem;
  margin: 0.5rem;
  width: 100%;
}

.result{
  width: 40vw;
}
.answer {
  background-color: rgb(136, 238, 136); 
  padding: 0.5rem; /* Padding for better visibility */
  /* background-color: #de3500; */
}
 
.quiz-icon {
  color : black
}


.loadanim {
  position: relative;
  width: 100px;
  height: 130px;
  background: #776e6e;
  border-radius: 4px;
}
.loadanim:before{
  content: '';
  position: absolute;
  width: 54px;
  height: 25px;
  left: 50%;
  top: 0;
  background-image:
  radial-gradient(ellipse at center, #0000 24%,#de3500 25%,#de3500 64%,#0000 65%),
  linear-gradient(to bottom, #0000 34%,#de3500 35%);
  background-size: 12px 12px , 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  transform: translate(-50% , -65%);
  box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
}
.loadanim:after{
  content: '';
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 66%;
  height: 60%;
  background: linear-gradient(to bottom, #f79577 30%, #0000 31%);
  background-size: 100% 16px;
  animation: writeDown 2s ease-out infinite;
}

  
/* Add these styles to your CSS file */

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	overflow: auto;
	padding: 20px;
	min-width: 90vw;
	min-height: 90vh;
	border-radius: 8px;
	z-index: 1000;
  }
  
  .full-screen-modal {
	position: fixed;
	top: 5;
	left: 10;
	right: 0;
	bottom: 0;
	background: white;
	padding: 80px;
	overflow: auto;
	z-index: 1000;
  }
  
  .overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999;
  }


  /* App.css */

  .flex-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
  }
  
  